import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Other = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='service-page'>
            <Helmet>
                <title>Other Services</title>
                <meta name="description" 
                      content="We offer mapping, 3D modeling, and inspections using our drones as well as valuable insights, measurements, and visualizations for efficient land management and urban planning."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>
                <h1 className='page-header-top-title'>Our Services /</h1>
                <h1 className='page-header-title'>Other Services</h1>
            </section>
            <section className="text-left-section service-left">
                <div className="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className="text-left-content">
                                <div className="text-left-content-text">
                                    <h1>Mapping and 3D Models</h1>
                                    <p>
                                    Mapping and 3D modeling are crucial for a variety of industries, offering 
                                    valuable insights and precise measurements. Accurate mapping enables 
                                    efficient land management, urban planning, and environmental monitoring, 
                                    while 3D modeling provides realistic visualizations and aids in infrastructure 
                                    development.
                                    </p>
                                    <p>
                                    At CNY Drone Services, we do both by providing superior mapping and 3D modeling 
                                    services using our advanced drone technology. Our drones capture high-resolution 
                                    aerial imagery and employ cutting-edge software to generate detailed maps and
                                     models with unparalleled accuracy.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Mapping and 3D Models" id="text-left-img" src="assets/services/other/new_mapping.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service-page-gypsy-moth" className="text-right-section service-right last-service-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Drone Inspections" id="text-right-img" src="assets/services/other/m300.webp"/>
                        </div>
                        <div className='col-lg-6'>
                            <div className="text-right-content">
                                <div className="text-right-content-text">
                                    <h1>Tower, Bridge, and Building Inspections</h1>
                                    <p>
                                    Tower, bridge, and building inspections play a vital role in ensuring structural integrity, 
                                    safety, and compliance with regulations. These inspections help identify potential risks, 
                                    defects, or maintenance needs, minimizing the likelihood of accidents and costly repairs.
                                    </p>
                                    <p>
                                    At CNY Drone Services, we deliver high-quality imagery with our specialized drones. 
                                    With our drone-based approach, we can access hard-to-reach areas, reduce the 
                                    need for scaffolding or risky manual inspections, and deliver efficient 
                                    and cost-effective results.
                                    </p>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a className="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Other;