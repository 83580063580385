import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Insects = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='service-page'>
            <Helmet>
                <title>Invasive Insects</title>
                <meta name="description" 
                      content="From the spongy gypsy moth to the lanternfly, our drone-powered pesticide solutions are designed to minimize the destructive spread of invasive species that wreck havor on ecosystems - keeping your trees alive and healthy."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>
                <h1 className='page-header-top-title'>Our Services /</h1>
                <h1 className='page-header-title'>Invasive Insects</h1>
            </section>
            <section className="text-left-section service-left">
                <div className="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className="text-left-content">
                                <div className="text-left-content-text">
                                    <h1>Understanding the Threat</h1>
                                    <p>
                                    Spongy Moth is one of the most destructive pests of hardwood trees in the Eastern US. It is listed as one of the top 100 most destructive invasive species in the world. During outbreak years, nearly all broadleaf trees may be completely defoliated, caterpillars appear everywhere, and “frass” (caterpillar droppings) appear to rain from the trees. Adult spongy moths are only seen in mid-summer when temperatures are above freezing. This species is known to infest trees in woodland or suburban areas.
                                    </p>
                                    <p>
                                    Areas in Northern Adirondacks, Central and Finger Lakes Region in New York have experienced heavy defoliation the past two years. When populations are high there are an estimated one million caterpillars per acre in NYS forests.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Multiple Caterpillars" id="text-left-img" src="assets/services/insects/caterpillars.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service-page-gypsy-moth" className="text-right-section service-right">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Hungry Caterpillar Eating Leaves" id="text-right-img" src="assets/services/insects/gypsy_moth_new_.jpg"/>
                        </div>
                        <div className='col-lg-6'>
                            <div className="text-right-content">
                                <div className="text-right-content-text">
                                    <h1>Combating Spongy (Gypsy) Moth </h1>
                                    <p>
                                    Foray 48B was specifically developed to protect forests from harmful defoliation caused by destructive caterpillar pests. Btk is a unique, naturally occurring bacterium that affects only caterpillars and, because of the unique chemistry of the caterpillar's gut, Foray 48B Btk is highly selective in its activity. It has minimal environmental impact, and it will not harm other types of insects, fish, birds or mammals.
                                    </p>
                                    <p>
                                    Foray 48B has been certified for use in Certified Organic Production under the National Organic Program (NOP) and it is also listed by the Organic Materials Review Institute (OMRI).
                                    </p>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-left-section service-left">
                <div className="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className="text-left-content">
                                <div className="text-left-content-text">
                                    <h1>Hemlock Woolly Adelgid</h1>
                                    <p>
                                    The Hemlock Woolly Adelgid poses a severe threat to hemlock trees, an 
                                    important component of forest ecosystems. These invasive insects feed 
                                    on the sap of hemlock trees, causing extensive damage and eventual 
                                    death if left unchecked. Their ability to reproduce rapidly and their 
                                    resilience in harsh conditions make them highly destructive. Swift 
                                    action is crucial to combat this invasive species and protect our 
                                    valuable hemlock forests.
                                    </p>
                                    <p>
                                    Utilizing cutting-edge drone technology, we tackle the hemlock woolly 
                                    adelgid infestation with precision and efficiency, safeguarding 
                                    hemlock trees and restoring ecological balance.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Untreated Neighbors' Tree" id="text-left-img" src="assets/services/insects/adelgid-new.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-right-section service-right last-service-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Lantern Fly" id="text-right-img" src="assets/services/insects/lanternfly.webp"/>
                        </div>
                        <div className='col-lg-6'>
                            <div className="text-right-content">
                                <div className="text-right-content-text">
                                    <h1>Safeguarding Against Spotted Lanternfly</h1>
                                    <p>
                                    The spotted lanternfly presents another significant threat to various industries 
                                    and ecosystems, particularly in affected regions. These invasive insects feed 
                                    on a wide range of plants, including economically important crops and valuable trees.
                                    Their rapid reproduction, ability to spread over large distances, and destructive 
                                    feeding habits make them a formidable pest, requiring immediate action to prevent 
                                    widespread damage and preserve the ecological balance.
                                    </p>
                                    <p>
                                    We tackle this threat with our precise drone-powered pesticides, designed in minimizing the 
                                    spread of lanternfly across New York State and the rest of the Northeast.
                                    </p>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a className="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default Insects;