import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Services = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div id="services-page">
            <Helmet>
                <title>Our Services</title>
                <meta name="description" 
                      content="We offer drone applications towards invasive insects and plants, cover crop seeding, and more."
                />
            </Helmet>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img mobile_img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>Our Services</h1>
            </section>
            <section className="text-left-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-left-img'>
                            <div className='service-img-overlay'>
                                <h1>Invasive Insects</h1>
                                <p>
                                    From the spongy gypsy moth to the lanternfly,  
                                    our drone-powered pesticide 
                                    solutions are designed to minimize the destructive 
                                    spread of invasive species that wreck havor on 
                                    ecosystems - keeping your trees alive and healthy.
                                </p>
                                <a className="main-button" href="/invasive-insects">Learn More<i className='fa fa-arrow-right'></i></a>
                            </div>
                            <img alt="Rick with the Drone" id="text-left-img" src="assets/home/CNY_Caterpillars_home.webp"/>
                        </div>
                        <div className='col-lg-6 about-right-img'>
                            <div className='service-img-overlay'>
                                <h1>Aquatic Applications</h1>
                                <p>
                                    We harness the power of drones for effective removal
                                    of invasive aquatic plants such as the water chestnut and
                                    common reed as well as effective monitoring of
                                    harmful algal bloom near water bodies.
                                </p>
                                <a className="main-button" href="/aquatic-applications">Learn More<i className='fa fa-arrow-right'></i></a>
                            </div>
                            <img alt="Drone in the Truck" id="text-left-img" src="assets/home/algae.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="second-row-services" className="text-left-section second-row">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-left-img'>
                            <div className='service-img-overlay'>
                                <h1>Agricultural Solutions</h1>
                                <p>
                                    With our large capacity drones, we tackle the devastation that 
                                    fungus and thistle cause on local ecosystems. Furthermore, we
                                    utilize our advanced drone technology to provide cover
                                    crop seeding.
                                </p>
                                <a className="main-button" href="/agricultural-solutions">Learn More<i className='fa fa-arrow-right'></i></a>
                            </div>
                            <img decoding="async" alt="Rick with the Drone" id="text-left-img" src="assets/home/CNY_Drones_spraying.webp"/>
                        </div>
                        <div className='col-lg-6 about-right-img'>
                            <div className='service-img-overlay'>
                                <h1>Other Services</h1>
                                <p>
                                    We offer mapping, 3D modeling, and inspections 
                                    using our drones as well as valuable 
                                    insights, measurements, and visualizations for 
                                    efficient land management and urban planning.
                                </p>
                                <a className="main-button" href="/other-services">Learn More<i className='fa fa-arrow-right'></i></a>
                            </div>
                            <img decoding="async" alt="Drone in the Truck" id="text-left-img" src="assets/services/other/m300.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a class="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default Services;