import React from 'react';
import { useEffect } from 'react';

const Navbar = () => {

    //  ------------- UNDERLINE NAV IF ON THAT PAGE -------------

      useEffect(() => {   

        var home_domain = window.location.href;

        var homelink = document.getElementById("homelink");
        var aboutlink = document.getElementById("aboutlink");
        var serviceslink = document.getElementById("serviceslink");
        var newslink = document.getElementById("newslink");


        if (window.innerWidth > 700) {

          if ((home_domain === "http://localhost:3000/")
            || (home_domain === "https://cnydroneservices.netlify.app/") 
            || (home_domain === "http://cnydroneservices.com/")
            || (home_domain === "https://cnydroneservices.com/")  
            )
          {
            homelink.firstChild.style.borderBottom = '0.2em solid #60A7C0';
          }

          if ((home_domain === "http://localhost:3000/about")
            || (home_domain === "https://cnydroneservices.netlify.app/about") 
            || (home_domain === "http://cnydroneservices.com/about")
            || (home_domain === "https://cnydroneservices.com/about")  
            )
          {
            aboutlink.firstChild.style.borderBottom = '0.2em solid #60A7C0';
          }

          if ((home_domain === "http://localhost:3000/services")
            || (home_domain === "https://cnydroneservices.netlify.app/services") 
            || (home_domain === "http://cnydroneservices.com/services")
            || (home_domain === "https://cnydroneservices.com/services")  
            )
          {
            serviceslink.firstChild.style.borderBottom = '0.2em solid #60A7C0';
          }

          if ((home_domain === "http://localhost:3000/news")
            || (home_domain === "https://cnydroneservices.netlify.app/news") 
            || (home_domain === "http://cnydroneservices.com/news")
            || (home_domain === "https://cnydroneservices.com/news")  
            )
          {
            newslink.firstChild.style.borderBottom = '0.2em solid #60A7C0';
          }

        }

      }, []);

      useEffect(() => {   

        var nav = document.getElementById("navid");
        var navimg = document.getElementById("logo_img");
        var dropdown_btn = document.getElementById("dropdown_btn");

        const navlinks = document.querySelectorAll('[id="navlink"]');
              
        window.addEventListener("scroll", () => { 
            
            navimg.style.width = "120px";
            navimg.style.height = "80px";
            nav.style.backgroundColor = '#fff';
            nav.style.boxShadow = "#19191933 0px 8px 30px 0px";

            dropdown_btn.firstChild.style.color = 'black';

            const navlinksArray = [...navlinks];
            navlinksArray.forEach(id => {
                id.style.color = 'black';
  
                id.addEventListener("mouseover", () => { 
                  id.style.color = 'black';
                });
                  
                id.addEventListener("mouseout", () => { 
                  id.style.color = 'black';
                });
          });

            if (!window.pageYOffset && window.innerWidth > 700) { 
                navimg.style.height = "120px";
                navimg.style.width = "180px";
                nav.style.boxShadow = 'none';
                nav.style.backgroundColor = 'transparent';

                dropdown_btn.firstChild.style.color = 'white';
                      
                const navlinksArray = [...navlinks];
                navlinksArray.forEach(id => {
                      id.style.color = 'white';
      
                      id.addEventListener("mouseover", () => { 
                        id.style.color = 'white';
                      });
                        
                      id.addEventListener("mouseout", () => { 
                        id.style.color = 'white';
                      });
                });
          }
        });

      }, []);

    return (
      <nav id='navid' className="navbar fixed-top navbar-expand-lg">
      <div className="container-fluid">
        <a className="navbar-brand" href="/"><img decoding="async" id='logo_img' alt='CNY Drone Services Logo' className='company_logo' src='assets/CNY_Drone_Services_Logo.webp'/></a>
         <button className="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="toggler-icon top-bar"></span>
            <span className="toggler-icon middle-bar"></span>
            <span className="toggler-icon bottom-bar"></span>
          </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
              <ul className="navbar-nav">
                <li id="homelink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/">Home</a>
                </li>
                <li id="aboutlink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/about">About Us</a>
                </li>
                {/* <li id="serviceslink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/invasive-insects">Our Services</a>
                </li> */}
                <li id="serviceslink" className="nav-item">
                  <div className="dropdown" >
                    <button id="dropdown_btn" className="dropbtn">
                    <a id='services-dropdown' href="/services">Our Services</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/invasive-insects">Invasive Insects</a>
                      <a href="/aquatic-applications">Aquatic Applications</a>
                      <a href="/agricultural-solutions">Agricultural Solutions</a>
                      <a href="/other-services">Other Services</a>
                    </div>
                  </div> 
                </li>
                <div className='mobile-nav-services'>
                <li id="newslink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/services">Our Services</a>
                </li>
                </div>
                <li id="aboutlink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/media">Media</a>
                </li>
                <li id="newslink" className="nav-item">
                  <a id="navlink" className="nav-link" href="/news">News</a>
                </li>
                {/* <li id="serviceslink" className="nav-item">
                  <div className="dropdown">
                    <button id="dropdown_btn" className="dropbtn">
                    <a href="/services">Get a Quote</a>
                    </button>
                    <div className="dropdown-content">
                      <a href="/upload-map">Upload Map</a>
                    </div>
                  </div> 
                </li> */}
                <li id="order-online-nav" className="nav-item">
                  <a id="navlink" className="nav-link" href="/contact">Get a Quote</a>
                </li>
              </ul>
          </div>
      </div>
    </nav>
    );
}

export default Navbar;