import React from 'react';
import { useEffect } from 'react';

const Footer = () => {

    return (
        <footer>
            <section id="footer-section">
                <div className='container'>
                    <div className='footer'>
                        <div className="footer-row">
                            <div className="footer-col">
                                <img decoding="async" alt="CNY Drone Services Logo" className='footer-img' src="assets/CNY_Drone_Services_Logo.webp"/>
                            </div>
                            <div className='footer-col'>
                                <div className='footer-txt'>
                                    <ul>3883 Dawes Ave, Clinton NY 13323</ul>
                                    <ul><a href="mailto:info@cnydroneservices.com">info@cnydroneservices.com</a></ul>
                                    <ul><a href="tel:315-351-6516">315-351-6516</a></ul>
                                    <ul><a aria-label="facebook" href="https://www.facebook.com/CNYDroneServices"><i className='fab fa-facebook fa-2x'></i></a></ul>
                                </div>
                            </div>
                            <div id="footer-nav-links" className="footer-col">
                                <br/>
                                <div className='footer-txt'>
                                    <ul><a id='nav-link-footer' className='nav-link' href='/'>Home</a></ul>
                                    <ul><a id='nav-link-footer' className='nav-link' href='/about'>About</a></ul>
                                    <ul><a id='nav-link-footer' className='nav-link' href='/services'>Our Services</a></ul>
                                    <ul><a id='nav-link-footer' className='nav-link' href='/news'>News</a></ul>
                                    <ul><a id='nav-link-footer' className='nav-link' href='/contact'>Contact Us</a></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='copyright-footer'>
                            <p className='copyright-txt'>© Copyright 2023 - 2024 |  Designed and Custom-Coded by
                            <span><a href="http://unawebsolutions.com"> Una Web Solutions</a></span>
                            </p>
                        </div>
                    </div>
                </div>
        </section>
    </footer>
    );
}

export default Footer;