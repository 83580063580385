import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Media = () => {

    useEffect(() => {   

        // const emailbox = document.getElementById('emailbox');
        // const email = document.getElementById('email');
        // emailbox.addEventListener('click', function() {
        // if (email.hasAttribute('readonly')) {
        //     email.removeAttribute('readonly')
        // } else {
        //     email.setAttribute('readonly', '"readonly"');
        // }
        // })

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }

    }, []);

    return (
        <div id="media-page">
            <Helmet>
                <title>Media</title>
                <meta name="description" 
                      content="We are more than happy to answer any of your questions and will get back to you as soon as possible."
                />
            </Helmet>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                    </div>                
                <h1 className='page-header-title'>Media</h1>
            </section>
            <section> 
                <div className='container'>
                <p>Watch our Farmer Forum Panel at  <a target="_blank" href='https://growiwm.org/farmer-forum-recap-using-drones-for-weed-management/'>GROWIWM.ORG</a></p>
                    <div className='top-media'>
                        <iframe src="https://www.youtube.com/embed/S6egt3NZgro" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <a target="_blank" href='https://auburn.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=efd17337-7106-4c3d-b771-b139011a12e1'>
                                <img alt="CNY Drone Services" src="assets/news/cnydronespanelfeb28.jpg"/>
                            </a>
                            <br/><br/>
                            <p><a target="_blank" href='https://auburn.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=efd17337-7106-4c3d-b771-b139011a12e1'>Watch our Feb 28 Panel</a></p>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <a target="_blank" href='https://auburn.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=b10edbfb-d922-4d0f-be69-b13901217956'>
                                <img alt="CNY Drone Services" src="assets/news/cnydronespanelfeb29.jpg"/>
                            </a>
                            <br/><br/>
                            <p><a target="_blank" href='https://auburn.hosted.panopto.com/Panopto/Pages/Viewer.aspx?id=b10edbfb-d922-4d0f-be69-b13901217956'>Watch our Feb 29 Panel</a></p>
                        </div>
                    </div>
                    <br/><br/><br/>
                    <div className='top-media'>
                        <iframe src="https://player.pbs.org/viralplayer/3084968143?t=420s" allowfullscreen allow="encrypted-media"></iframe>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media1.webp"/>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media2.webp"/>
                        </div>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media3.webp"/>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media4.webp"/>
                        </div>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media16.jpg"/>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media6.webp"/>
                        </div>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media7.webp"/>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media15.JPG"/>
                        </div>
                    </div>
                    <div className='row row-media'>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media9.webp"/>
                        </div>
                        <div className='col-lg-6 media-body'>
                            <img alt="CNY Drone Services" src="assets/media/media12.jpg"/>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Media;