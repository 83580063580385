import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const CountryFolks = () => {

    useEffect(() => {  

        var headerVideo = document.getElementById("header-video");


        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='mothblog-page'>
            <Helmet>
                <title>Advances in drones for pest management</title>
                <meta name="description" 
                      content="The invasive spongy moth and its caterpillars can decimate a garden. Here's how to fight back."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>News</h1>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News /
                        </div>
                        <h1><a href="/news">Back to All Posts</a></h1>  
                        <hr/>                     
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div>
                                    <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/>
                                    <div id="blockquote" className="card-body blockquote">
                                        <p id="mothblog-title" className="card-text">
                                            <strong>Advances in drones for pest management</strong>
                                        </p>
                                    </div>
                                </div>     
                                <div>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/news/blog3_cnydrones.jpg"/>
                                </div>   
                                <div id="blockquote" className="card-body blockquote">
                                    <p className='blog-text-smaller'>02/01/2024</p>
                                    <p className='blog-text'>
                                    As with other types of emerging technologies, unmanned aerial vehicles, commonly called drones, have been making inroads in agriculture in making tough tasks easier. The 2024 Corn Congress included a panel discussion on drones, “Using Drones for Effective Pest Management,” featuring Rick Jordan, owner of CNY Drone Services in Clinton, NY; Tom Wolf, senior researcher with Agrimetrix Research and Training, Saskatoon, Saskatchewan; and Bryan Young, weed scientist with Purdue University. Mike Stanyard, Cornell field crops specialist, emceed.
                                    </p>
                                    <p className='blog-text'>
                                    “What are the benefits of using drones?” Stanyard asked.
                                    </p>
                                    <p className='blog-text'>
                                    “It’s cost-effective in places where you can’t get [manned] aerial [equipment] in,” Jordan answered. “There’s windmills, power lines and a lot of forestry applications, as we can penetrate the canopy with our equipment.”
                                    </p>
                                    <br/>
                                    <p className='blog-text'>
                                     Read the full article <span><a href="https://countryfolks.com/advances-in-drones-for-pest-management/">here</a></span>.
                                    </p>
                                    <br/><br/>
                                    <hr/>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default CountryFolks;