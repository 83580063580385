import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const MothBlog = () => {

    useEffect(() => {  

        var headerVideo = document.getElementById("header-video");


        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='mothblog-page'>
            <Helmet>
                <title>What to Do if You See a Spongy Moth</title>
                <meta name="description" 
                      content="The invasive spongy moth and its caterpillars can decimate a garden. Here's how to fight back."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>News</h1>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News /
                        </div>
                        <h1><a href="/news">Back to All Posts</a></h1>  
                        <hr/>                     
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div>
                                    <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/>
                                    <div id="blockquote" className="card-body blockquote">
                                        <p id="mothblog-title" className="card-text">
                                            <strong>What to Do if You See a Spongy Moth (or Its Very Hungry Caterpillars)</strong>
                                        </p>
                                        <p className='blog-text'>The invasive spongy moth and its caterpillars can decimate a garden. Here's how to fight back.
                                        </p>
                                    </div>
                                </div>     
                                <div>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/home/CNY_Drone_home_news.webp"/>
                                </div>   
                                <div id="blockquote" className="card-body blockquote">
                                    <p className='blog-text-smaller'>By Margaret Roach</p>
                                    <p className='blog-text-smaller'>Published Sept. 21, 2022 - Updated Sept. 22, 2022</p>
                                    <p className='blog-text'>Until this year, the only spongy moths I had encountered were the winged adults — the occasional males I counted in nighttime surveys every July, during the citizen-science events of National Moth Week.</p>
                                    <p className='blog-text'>
                                    I knew part of the moth/s story, beginning with its accidental release in 
                                    Massachusetts around 1869. I had seen maps of how this European species, 
                                    Lymantria dispar, had slowly spread to at least 20 Eastern and Midwestern 
                                    states, as well as five Canadian provinces, becoming a serious forest pest. 
                                    And I knew that the moth had a preference for oak trees, although it had 
                                    been recorded feeding on several hundred tree and shrub species.
                                    </p>
                                    <p className='blog-text'>
                                    This spring I was finally introduced to its very hungry caterpillars, the larval stage 
                                    of the insect formerly known as the gypsy moth before it was <span><a href="https://www.nytimes.com/2022/03/03/science/spongy-moth-romani.html">renamed</a></span> this year. 
                                    It was not a “pleased to meet you” moment.
                                    </p>
                                    <p className='blog-text'>
                                    This is one moth I don't welcome in my moth-friendly garden. Nevertheless, making its acquaintance was darkly fascinating, because even when I cannot fix something, understanding what I am observing reassures me.
                                    </p>
                                    <br/><br/>
                                    <p className='blog-text'>
                                    The caterpillars' behavior raised many questions, including this unsettling one: How did they manage to find every witch hazel relative in my garden, as if they possessed some kind of botanical GPS? 
                                    Somehow they located not just the Hamamelis, but also the Fothergilla and winterhazel (Corylopsis).
                                    </p>
                                    <img decoding="async" alt="Moth on a Tree" className="card-img" src="assets/news/what-to-do-moth/blog1-img1.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    Even more anxiety-inducing is the thought of what 2023 may bring. Is there anything I can do to 
                                    minimize the spongy moths' destructive effect on my garden? The answer is probably yes — at least 
                                    if I'm focusing on a few special trees, taking action this fall and in the next growing season.
                                    </p>
                                    <p className='blog-text'>
                                    As those male moths with their patterned brown-and-tan wings and feathery antennae were telling me, 
                                    this was not, in fact, my first caterpillar season. “They were there all along, just at really low 
                                    density,” said Ann Hajek, a professor in the department of entomology at Cornell, where she began 
                                    studying the moths in 1985, as a postdoctoral researcher. Since then, she has studied a number of 
                                    other destructive invasive insects, including the Asian long-horned beetle, the brown marmorated 
                                    stink bug and the seemingly ubiquitous spotted lanternfly. In areas where the spongy moth is established, 
                                    Dr. Hajek said, each big outbreak lasts two or three years, separated by multiyear downturns of five, 
                                    10 or even 30 years. In Ithaca, N.Y., 2022 was a spongy moth outbreak year — the first since 1992 — 
                                    and reports of high populations also came from parts of Pennsylvania, Connecticut, Vermont and 
                                    Michigan and elsewhere.
                                    </p>
                                    <br/>
                                    <img decoding="async" alt="Multiple Moths on a Tree" className="card-img" src="assets/news/what-to-do-moth/blog1-img2.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    Power in Numbers
                                    </p>
                                    <p className='blog-text'>
                                    The caterpillars may not be obvious at first, even during a big outbreak. “In May, you can have a 
                                    lot of caterpillars, and you won't even notice,” Dr. Hajek said, referring to the month they 
                                    emerge in Ithaca and the Northeast. But that can be costly: If you're going to push back with 
                                    a properly timed spray of a biological insecticide containing Bacillus thuringiensis var. 
                                    kurstaki (Btk), she warned, “you need to control them when they're small.”
                                    </p>
                                    <p className='blog-text'>
                                    <br/>
                                    It all happens fast.
                                    </p>
                                    <p className='blog-text'>
                                    Spongy moths produce just one generation a year. But oh, what a generation. It's a story of power 
                                    in numbers, as each female moth can produce an egg mass holding up to 800 eggs. On their way to 
                                    adulthood, spongy moths go through multiple larval stages, or instars, over six to eight weeks in 
                                    spring — five instars for males, six for females. Then they transition to pupae, or chrysalises, 
                                    for a week or two before emerging, or eclosing, for a short, winged adulthood.
                                    </p>
                                    <br/>
                                    <img decoding="async" alt="Male and Female Moths" className="card-img" src="assets/news/what-to-do-moth/blog1-img3.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    Male and female moths look quite different: The males are dark, but the females are white with dark markings, and much larger.                                    
                                    </p>
                                    <p className='blog-text'>
                                    Only the males are capable of flight. Guided by pheromones, they fly by day over about a two-week period in 
                                    search of mates. Mated females then lay eggs in tan-colored masses on trees and other surfaces near 
                                    wherever they emerged. The embryos develop inside, and the immature insects rest for about nine and 
                                    a half months in suspended development, or diapause.                                    
                                    </p>
                                    <p className='blog-text'>
                                    Around the time of the trees' springtime bud burst, the larvae chew through the egg mass. Then they really 
                                    get moving. The early instars, less than a quarter of an inch long, climb the tree from which they 
                                    emerged or one that is close by. Dangling from a limb on a silk thread, they await a breeze to send 
                                    their hairy, flight-adapted bodies sailing — a dispersal method known as ballooning. Although a 
                                    typical journey is less than 150 yards, some research puts the possible distance at up to half a mile.
                                    </p>
                                    <p className='blog-text'>
                                    With so many individuals capable of traveling so far, it is no wonder that some of them found each of my witch hazel relatives.
                                    </p>
                                    <br/>
                                    <img decoding="async" alt="Young Moths" className="card-img" src="assets/news/what-to-do-moth/blog1-img4.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    There may be return trips, back up the trees and down again, and even walkabouts, especially by older caterpillars. 
                                    Unlike the day-feeding, younger instars, those in the last two stages, feed at night, crawling down into bark 
                                    crevices or leaf litter to hide during daylight, presumably to avoid predation. 
                                    </p>
                                    <p className='blog-text'>
                                    One summertime control strategy aimed at limiting the damage the moths do takes advantage of that behavior. The strategy won't 
                                    protect every tree in a forest, Dr. Hajek said, but it can be used on particular trees in a park or garden. Instead of encircling 
                                    a tree with sticky bands — a strategy sometimes recommended to trap ascending or descending caterpillars, but one that can harm 
                                    other insects and birds — try wrapping the trunk with a strip of 12- to 18-inch-wide burlap at around chest height and 
                                    securing it with string midway up the swath of fabric. Flap the upper half down over the string to create a daytime hiding 
                                    place and check it every afternoon, sweeping the resting caterpillars into soapy water. Because it is estimated that 80 percent 
                                    or more of the foliage consumed by spongy moths is eaten during the last two instars, reducing the number of mature caterpillars 
                                    on a treasured tree can pay off.
                                    </p>
                                    <br/>
                                    <img decoding="async" alt="Caterpillar on Leaf" className="card-img" src="assets/news/what-to-do-moth/blog1-img5.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    Natural Enemies and Human Interventions
                                    </p>
                                    <p className='blog-text'>
                                    Weather and other environmental factors contribute to fluctuations in the spongy moth population. Cool, damp springs 
                                    give one of the moth's natural enemies, a fungal, soil-borne pathogen called Entomophaga maimaiga, an edge in 
                                    keeping the moths in check.                                    
                                    </p>
                                    <p className='blog-text'>
                                    “These really dry springs, we think, are why the spongy moths have taken off here,” Dr. Hajek said. “They seem to 
                                    be more successful when May and June are really dry, because the pathogen doesn't survive.” The fungus, which is 
                                    native to Japan, has been present in the United States for more than 30 years, its spores gradually blowing into 
                                    larger areas. Conservation scientists and land managers have helped speed up that process by distributing soil 
                                    containing the fungus (or the cadavers of infected caterpillars) into new areas to inoculate a wider zone.                               
                                    </p>
                                    <p className='blog-text'>
                                    Other natural spongy moth controls include the nuclear polyhedrosis virus (NPV) and various parasitoids, including 
                                    wasps and flies that attack the eggs or caterpillars. White-footed mice are a predator of the insect in its brief 
                                    summer pupal stage. In the year after a plentiful acorn crop on oak trees, mouse populations typically rise, 
                                    and more pupae may be devoured before the adult moths emerge to mate again. Since 1890, various human efforts have 
                                    sought to contain the population, too. A campaign called Slow the Spread that began in 1992 aims to suppress 
                                    infestation by monitoring moth activity just outside infested areas and eradicating isolated outbreaks caused by 
                                    human movement, using aerial spraying, among other strategies.                              
                                    </p>
                                    <br/>
                                    <img decoding="async" alt="Moth Effected Area" className="card-img" src="assets/news/what-to-do-moth/blog1-img6.webp"/>
                                    <p className='blog-text'>
                                    <br/>
                                    More Advice for Anxious Gardeners
                                    </p>
                                    <p className='blog-text'>
                                    Natural resource managers use binoculars, not crystal balls, to predict what will happen in the coming year, counting 
                                    egg masses in sample plots and checking all the way up trunks and limbs after trees drop their leaves. The total 
                                    number of egg masses they find helps them predict whether defoliation of more than 50 percent of the forest canopy 
                                    is likely. If so, it may warrant an intervention like spraying.
                                    </p>
                                    <p className='blog-text'>
                                    “A healthy tree can lose up to 50 percent of its leaves before it needs to produce a replacement set,” said Andrea 
                                    Diss-Torrance, the invasive forest insects program coordinator for the Wisconsin Department of Natural Resources. 
                                    But producing a replacement set of leaves is a costly process for a tree, she noted — especially one that is 
                                    already pressured by an outbreak or drought. In residential settings, where there are fewer trees, the egg-mass 
                                    threshold for concern is much lower than in forests. “With less foliage available to eat,” Dr. Diss-Torrance 
                                    said, “a lower population of the caterpillar is needed to consume greater than 50 percent of the leaves.”
                                    </p>
                                    <p className='blog-text'>
                                    Another clue about what to expect next year, Dr. Hajek said: Smaller egg masses, about the size of a dime, which 
                                    can indicate a declining population. What can gardeners do if their yards include the moth's preferred foods — not 
                                    just oak trees, but apples, willows, paper birch and all those Hamamelis cousins, among others? Protecting a few 
                                    key trees won't influence the greater outbreak. But it will have an aesthetic benefit, and it may make sitting 
                                    beneath the trees in June less repulsive by reducing the excreted frass dropping from above. Besides summer burlap 
                                    banding, trees can be treated in spring with Btk, the naturally occurring bacterium, when caterpillars are up to a 
                                    half-inch long. This cripples the digestive systems of caterpillars that consume the sprayed leaves within a week 
                                    of application. Dr. Diss-Torrance's advice: Reserve the service of a pesticide-licensed arborist now or risk 
                                    missing that narrow window in the spring. Where will you find me later this fall? Soaking any egg masses I see, 
                                    by spraying them with horticultural oil or carefully scraping them into soapy water — not onto the ground, 
                                    where some may survive.
                                    </p>
                                    <br/>
                                    <p className='blog-text'>
                                    As Dr. Diss-Torrance said, “That's hundreds of caterpillars per egg mass that you won't have to deal with next spring.”
                                    </p>
                                    <p className='blog-text'>
                                    My thought exactly.
                                    </p>
                                    <br/>
                                    <hr/>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default MothBlog;