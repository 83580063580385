import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';

const Home = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }

        var heroVideo = document.getElementById("CNY_Drone_Video");
        
        heroVideo.oncanplaythrough = function() {
            heroVideo.muted = true;
            heroVideo.setAttribute("playsinline", "");
            heroVideo.play();
        }
        var droneflyingvideo = document.getElementById("droneflyingvideo");
        
        droneflyingvideo.oncanplaythrough = function() {
            droneflyingvideo.muted = true;
            droneflyingvideo.setAttribute("playsinline", "");
            droneflyingvideo.play();
        }

        // const progress = document.querySelector('.progress-done');

        // progress.style.width = progress.getAttribute('data-done') + '%';
        // progress.style.opacity = 1;

        const openPopupButtons = document.querySelectorAll(".open_popup");
        const popupCloseButtons = document.querySelectorAll(".popup_close");
        const popupBackButtons = document.querySelectorAll(".popup_back");
        const popupBodies = document.querySelectorAll(".popup_body");

        openPopupButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupMain = this.closest(".popup_main");
                const popupBodyToShow = popupMain.querySelector(".popup_body");
                popupBodyToShow.classList.add("popup_body_show");
            });
        });

        popupCloseButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupBodyToHide = this.closest(".popup_body");
                popupBodyToHide.classList.remove("popup_body_show");
            });
        });

        popupBackButtons.forEach(function(button) {
            button.addEventListener("click", function() {
                const popupBodyToHide = this.closest(".popup_body");
                popupBodyToHide.classList.remove("popup_body_show");
            });
        });

        
    }, []);

    return ( 
        <div id="home-page"> 
            <Helmet>
                <title>Drone Service Provider | CNY Drone Services Inc. | Serving the Northeast</title>
                <meta name="description" 
                      content="CNY Drone Services Inc. is a drone service provider firm, specializing in Agricultural, Aggregate, Construction, and more.  Offerings services such as Photogrammetry, Mapping, and Aerial Spraying/Seeding. 
                      We are insured, licensed, professionals ready to take your business to new heights!"
                />
            </Helmet>
           <section id="home-hero-section">
                <div id="home-hero-image-body">
                    <video id="CNY_Drone_Video" playsinline autoplay muted loop poster="assets/home/CNY_Drone_Hero_img.webp">
                        <source src="assets/home/CNY_Drone_Hero_Video.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Drone Shot of Field" className="mobile_img" src="assets/home/CNY_Drone_Hero_img.webp"/>
                    <div id="home-hero-content">
                        <div className="home-hero-content">
                            <div className="home-hero-content-text">
                                <h1>Defeat your Pests with Our Invasive Species Specialists</h1>
                            </div>
                            <a className="main-button" href="/invasive-insects">Discover More</a>
                        </div>
                    </div>
                </div>
            </section>
            <section id="book-section">
                <div className='row'>
                    <div className='col-lg-3 book-body'>
                        <h2>Spraying Season for Spongy Moths is Over.</h2>
                    </div>
                    <div className='col-lg-4 progress-body'>
                        <h3>Bookings Start in November, Save Your Spot Now!</h3>
                    </div>
                    <div className='col-lg-4 book-btn-body'>
                    <div class="popup_main">
                        <button class="open_popup">Signup for Next Year</button>
                        <div class="popup_body">
                            <div class="popup_back"></div>
                                <div class="popup_contain">
                                    <div class="popup_close">x</div>
                                    
                                            <iframe
                                            src="https://form.jotform.com/242355376117154"
                                            id='book-form'
                                            >
                                            </iframe>                                            
                                </div>
                            </div>
                        </div>
                    </div>
                 </div>
                
            </section>
            <section id="home-services-section">
                <div className='container'>  
                    <div className="home-services-content-text">
                        <div className='section-header'> 
                           Our Services 
                        </div>
                        <h1>Drone Services Across Various Industries</h1>
                        <p>
                        We specialize in providing effective aerial solutions for 
                        combating invasive insects and plants, whether they be on land or water. 
                        Safeguard your trees with our versatile and innovative offerings, 
                        tailored to meet your specific needs.
                        </p>
                    </div>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <div className="card">                              
                                <img alt="Caterpillars on a Tree" id="caterpillar_img" className='card-img' src="assets/home/caterpillars.webp" />
                                <img alt="Caterpillars on a Tree" id="mobile_caterpillar_img" className='card-img' src="assets/home/CNY_Caterpillars_home.webp" />
                                <h2>Invasive Insects</h2>
                                <div className='main-button-body'>
                                    <a className="main-button" href="/invasive-insects">Learn More<i className='fa fa-arrow-right'></i></a>
                                </div>                            
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="card">     
                                <img alt="Algae Build-up" className='card-img' src="assets/home/algae.webp" />                                                     
                                <h2>Aquatic Applications</h2>
                                <div className='main-button-body'>
                                    <a className="main-button" href="/aquatic-applications">Learn More<i className='fa fa-arrow-right'></i></a>
                                </div>                            
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="card"> 
                                <img alt="Drone Spraying Field" className='card-img' src="assets/home/agras_drone_new.webp" />                             
                                <h2>Agricultural Solutions</h2>
                                <div className='main-button-body'>
                                    <a className="main-button" href="/agricultural-solutions">Learn More<i className='fa fa-arrow-right'></i></a>
                                </div>                            
                            </div>
                        </div>
                        <div className='col-lg-3'>
                            <div className="card"> 
                                <img alt="Drone Mapping Area" className='card-img' src="assets/services/other/m300.webp" />                             
                                <h2>Other Services</h2>
                                <div className='main-button-body'>
                                    <a className="main-button" href="/other-services">Learn More<i className='fa fa-arrow-right'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="why-drones" className="text-left-section">
                <div className="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className="text-left-content">
                                <div className="text-left-content-text">
                                    <div className='section-header'> 
                                    Why Drones
                                    </div>
                                    <h1>Safeguarding Your Trees</h1>
                                    <p>
                                    Drones (or UAVs) have been around for over a decade! At CNY Drone Services, we are looking to the decades ahead to grow with this technology and our clients alike.
                                    </p>
                                    <p>
                                    Our drones allow us to safeguard your trees from invasive insects, plants, etc. with our specialized spraying services.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <video alt="Drone Flying Video" id="droneflyingvideo" height="500" autoplay loop>
                                <source src="assets/home/droneflying.mp4" type="video/mp4"/>
                            </video>
                            <img alt="Drone Flying" id="droneflyingimg" src="assets/home/droneflyingimg.png"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-why-us-section">
                <div id="home-why-us-image-body">
                    <img decoding="async" src="assets/home/CNY_Drone_service_area.webp" alt="Flying Drone Background"/>
                    <div id="home-why-us-content">
                        <div className="home-why-us-content-text">
                            <div className='section-header'> 
                            Our Service Area
                            </div>
                            <h1>The Northeast's Premier Drone Spraying Provider</h1>
                            <p>
                            We are based in Herkimer/Utica NY and proudly serve all of NYS and beyond! We are more than happy to travel to your field, business, or job site!
                            </p>
                        </div> 
                        <a className="main-button" href="/contact">Contact Us</a>
                    </div>
                </div>
            </section>
            <section id="home-video-section">
                <div id="home-video-image-body">
                    <h2>Watch Our Segment on WCNY PBS News!</h2>
                    <div id="news-video" playsinline autoplay muted loop>
                        <iframe src="https://player.pbs.org/viralplayer/3084968143?start=530" allowfullscreen allow="encrypted-media"></iframe>
                    </div>
                </div>
            </section> 
            <section id="spongy-moth-home" className="text-left-section">
                <div className="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className="text-left-content">
                                <div className="text-left-content-text">
                                    <div className='section-header'> 
                                    Pesticide Spraying
                                    </div>
                                    <h1>Combating Spongy (Gypsy) Moth</h1>
                                    <p>
                                    Spongy Moth is one of the most destructive pests of hardwood trees 
                                    in the Eastern US. It is listed as one of the top 100 most destructive 
                                    invasive species in the world. During outbreak years, nearly all 
                                    broadleaf trees may be completely defoliated, caterpillars appear 
                                    everywhere, and “frass” (caterpillar droppings) appear to rain 
                                    from the trees. Adult spongy moths are only seen in mid-summer 
                                    when temperatures are above freezing. This species is known to 
                                    infest trees in woodland or suburban areas.
                                    </p>
                                    <p>
                                    We use National Organic Program (NOP)-certified Foray 48B to
                                    combat these pests.
                                    </p>
                                </div>    
                                <a className="main-button" href="/invasive-insects">Learn More<i className='fa fa-arrow-right'></i></a>             
                            </div>
                        </div>
                        <div className='col-lg-6 spongy-moth-img'>
                            <img decoding="async" alt="Effect of Gypsy Moths on Field" id="text-left-img" src="assets/home/CNY_Drone_combat_gyp_moth.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-about-section" className="text-right-section">
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-6 home-about-img'>
                            <img decoding="async" alt="Us with a Drone" id="text-right-img" src="assets/about/about_us_new.webp"/>
                        </div>
                        <div className='col-lg-6'>
                            <div className="text-right-content">
                                <div className="text-right-content-text">
                                    <div className='section-header'> 
                                    Who We Are
                                    </div>
                                    <h1>Aerial Application Pros</h1>
                                    <p>
                                    We take great pride in our expertise and 
                                    dedication to providing exceptional drone services across New York 
                                    State and the entire Northeast region. With a strong focus on 
                                    combating invasive pests, we bring innovative 
                                    solutions to the rampant pest problem.
                                    </p>
                                    <p>
                                    Our team is committed to delivering outstanding results through 
                                    meticulous planning, cutting-edge technology, and a keen eye for 
                                    detail.  
                                    </p>
                                </div>           
                                <a className="main-button" href="/about">More About Us<i className='fa fa-arrow-right'></i></a>  
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            Space is limited and short windows of opportunity available, don't wait until it's too late. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a className="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section> 
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News
                        </div>
                        <h1>Latest Post</h1>                       
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div className='col-lg-4'>
                                    <img decoding="async" alt="Very Hungry Caterpillars" className="card-img" src="assets/home/CNY_Drone_home_news.webp"/>
                                </div>
                                <div className="col-lg-8">
                                    <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/>
                                    <div id="blockquote" className="card-body blockquote">
                                        <p className="card-text">
                                            What to Do if You See a Spongy Moth (or Its Very Hungry Caterpillars)
                                        </p>
                                    </div>
                                    <a className="main-button" href="/news">Read More</a>   
                                </div>        
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div> 
    );
}

export default Home;