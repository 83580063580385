import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Portfolio = () => {

    useEffect(() => {  

        var headerVideo = document.getElementById("header-video");


        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    
    return (
        <div className='news-page'>
            <Helmet>
                <title>News | All Posts</title>
                <meta name="description" 
                      content="Stay in touch with our news blog posts."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>News</h1>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News /
                        </div>
                        <h1>All Posts</h1>  
                        <hr/>                     
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div className='col-lg-4'>
                                    <img decoding="async" alt="How to Protect Your Trees from Gypsy Moths" className="card-img" src="/assets/news/howtoprotectyourtrees.jpg"/>
                                </div>
                                <div className="col-lg-8">
                                    {/* <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/> */}
                                    <div id="blockquote" className="card-body blockquote">
                                        <p className="card-text">
                                        How to protect your trees from this year’s moth infestation
                                        </p>
                                    </div>
                                    <a className="main-button" href="/how-to-protect-your-trees-from-this-years-moth-infestation">Read More</a>   
                                </div>        
                            </div>
                        </div>   
                    </div>
                    <br/><br/>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div className='col-lg-4'>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/news/blog3_cnydrones.jpg"/>
                                </div>
                                <div className="col-lg-8">
                                    {/* <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/> */}
                                    <div id="blockquote" className="card-body blockquote">
                                        <p className="card-text">
                                        Advances in drones for pest management
                                        </p>
                                    </div>
                                    <a className="main-button" href="/Advances-in-drones-for-pest-management">Read More</a>   
                                </div>        
                            </div>
                        </div>   
                    </div>
                    <br/><br/>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div className='col-lg-4'>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/news/news2.jpg"/>
                                </div>
                                <div className="col-lg-8">
                                    {/* <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/> */}
                                    <div id="blockquote" className="card-body blockquote">
                                        <p className="card-text">
                                        DCNR Urges Forest Landowners And Managers Seeking To Treat For Spongy Moth Caterpillars To Act Now
                                        </p>
                                    </div>
                                    <a className="main-button" href="/DCNR-Urges-Forest-Landowners-And-Managers-Seeking-To-Treat-For-Spongy-Moth-Caterpillars-To-Act-Now">Read More</a>   
                                </div>        
                            </div>
                        </div>   
                    </div>
                    <br/><br/>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div className='col-lg-4'>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/home/CNY_Drone_home_news.webp"/>
                                </div>
                                <div className="col-lg-8">
                                    {/* <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/> */}
                                    <div id="blockquote" className="card-body blockquote">
                                        <p className="card-text">
                                            What to Do if You See a Spongy Moth (or Its Very Hungry Caterpillars)
                                        </p>
                                    </div>
                                    <a className="main-button" href="/what-to-do-spongy-moth">Read More</a>   
                                </div>        
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Portfolio;