import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';

const Contract = () => {

    useEffect(() => {   

        // const emailbox = document.getElementById('emailbox');
        // const email = document.getElementById('email');
        // emailbox.addEventListener('click', function() {
        // if (email.hasAttribute('readonly')) {
        //     email.removeAttribute('readonly')
        // } else {
        //     email.setAttribute('readonly', '"readonly"');
        // }
        // })

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }


            //hides dropdown content
        // var sizeCharts = document.querySelectorAll(".size_chart");
        // sizeCharts.forEach(function (chart) {
        //     chart.style.display = "none";
        // });
        
        // //unhides first option content
        // document.getElementById("option1").style.display = "block";
        
        // //listen to dropdown for change
        // document.getElementById("size_select").addEventListener("change", function () {
        //     //rehide content on change
        //     sizeCharts.forEach(function (chart) {
        //     chart.style.display = "none";
        //     });
        //     //unhides current item
        //     document.getElementById(this.value).style.display = "block";
        // });
        
        
        
    //     var afterCard               = document.getElementById('after-card');
    //     var beforeCard               = document.getElementById('before-card');

    //     afterCard.style.display = 'none';

    //     var switchButton 			= document.querySelector('.switch-button');
    //     var switchBtnRight 			= document.querySelector('.switch-button-case.right');
    //     var switchBtnLeft 			= document.querySelector('.switch-button-case.left');
    //     var activeSwitch 			= document.querySelector('.active');

    //     function switchLeft(){
    //         switchBtnRight.classList.remove('active-case');
    //         switchBtnLeft.classList.add('active-case');
    //         activeSwitch.style.left 						= '0%';
    //         beforeCard.style.display = 'block';
    //         afterCard.style.display = 'none';
    //     }

    //     function switchRight(){
    //         switchBtnRight.classList.add('active-case');
    //         switchBtnLeft.classList.remove('active-case');
    //         activeSwitch.style.left 						= '50%';
    //         beforeCard.style.display = 'none';
    //         afterCard.style.display = 'block';

    //     }

    //     switchBtnLeft.addEventListener('click', function(){
    //         switchLeft();
    //     }, false);

    //     switchBtnRight.addEventListener('click', function(){
    //         switchRight();
    //     }, false);
        
    //    // Get all image elements
    //     const imageElements = document.querySelectorAll('.contract-img-body');

    //     // Get all content elements
    //     const contentElements = document.querySelectorAll('.contract-img-content');

    //     // Attach click event listeners to the image elements
    //     imageElements.forEach((imageElement) => {
    //         imageElement.addEventListener('click', () => {
    //             // Get the data-image attribute to determine which content to show
    //             const imageNumber = imageElement.getAttribute('data-image');

    //             // Hide all content elements
    //             contentElements.forEach((contentElement) => {
    //                 contentElement.classList.remove('active-content');
    //             });

    //             // Show the content associated with the clicked image
    //             const activeContent = document.querySelector(`[data-content="${imageNumber}"]`);
    //             activeContent.classList.add('active-content');


    //             const contractLeftBtn = document.getElementById("contract-left-btn");
    //             const contractRightBtn = document.getElementById("contract-right-btn");
        
    //             const contractCnyDrones = document.getElementById("contract-cny-drones");
    //             const mucc = document.getElementById("mucc");
        
    //             contractLeftBtn.addEventListener('click', () => {

    //                 contentElements.forEach((contentElement) => {
    //                     contentElement.classList.remove('active-content');
    //                 });

    //                 contractCnyDrones.classList.add('active-content');
                    
    //             });
        
    //             contractRightBtn.addEventListener('click', () => {

    //                 contentElements.forEach((contentElement) => {
    //                     contentElement.classList.remove('active-content');
    //                 });

    //                 mucc.classList.add('active-content');

    
    //             });

    //         });
    //     });  


    }, []);

    return (
        <div id="contract-page">
            <Helmet>
                <title>Contract</title>
                <meta name="description" 
                      content="We are more than happy to answer any of your questions and will get back to you as soon as possible."
                />
            </Helmet>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                    </div>                
                <h1 className='page-header-title'>Pre-Booking Contract</h1>
            </section>
            <section className="text-right-section">
                <div class="container">
                    <div className='contract-text'>
                        <h2>Spongy Moth Pre-Booking Contract</h2>
                        <p>Please complete this form fully and submit it to be scheduled for these services. After your information is 
                            reviewed/confirmed, an invoice will be generated and sent to you for payment.
                        </p>
                        <br/>                
                        <p>Filling out this application accurately enables us to process the "paperwork" much faster to ensure services 
                            are scheduled correctly. This also ensures we are able to order enough material in time.
                        </p>
                        <p>Please make sure this application is filled out in it's entirety.</p>
                        <p>Thank you for your cooperation!</p>
                    </div>
                    {/* <div className='jotform'>
                        <JotformEmbed src="https://form.jotform.com/233135560598158" />
                    </div>                 */}
                        {/* <select id="size_select">
                            <option value="option1">Kids</option>
                            <option value="option2">Youth</option>
                        </select>
                        <div id="option1" class="size_chart">
                            <div className='jotform'>
                                <JotformEmbed src="https://form.jotform.com/233137037358153" />
                            </div> 
                        </div>
                        <div id="option2" class="size_chart">
                            <div className='jotform'>
                                <JotformEmbed src="https://form.jotform.com/233136894009156" />
                            </div> 
                        </div> */}
                        {/* <div>
                            <h3>Choose A Payment Option Below</h3>
                            <div className='switch-body'>
                                <div class="switch-button">
                                    <span class="active"></span>
                                    <button id="contract-left-btn" class="switch-button-case left active-case">MAIL A CHECK</button>
                                    <button id="contract-right-btn" class="switch-button-case right">PAY WITH CARD</button>
                                </div>
                            </div>
                        </div> */}
                        <div className='contract-examples'>
                            <div>
                                <div id="extra-service-card">
                                    {/* <div id="before-card">
                                        <div>
                                            <div className='jotform'>
                                                <iframe src="https://form.jotform.com/233137037358153" />
                                            </div> 
                                        </div>
                                    </div> */}
                                    <div id="after-card">
                                        <div>
                                            <div className='jotform'>
                                                <iframe src="https://form.jotform.com/233136894009156" />
                                            </div> 
                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                        
                </div>
            </section>
        </div>
    );
}

export default Contract;