import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const About = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true; 
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }


        // window.addEventListener(
        //     "resize", zoomIn, false);
          
        let aboutPageImg = document.getElementById("about-page-img");

        console.log(window.innerWidth);

        if ( window.innerWidth < 1440) {
            aboutPageImg.style.paddingTop = '15%';
        }

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div id="about-page">
            <Helmet>
                <title>About Us</title>
                <meta name="description" 
                      content="We are your trusted experts in aerial drone applications across New York State and the Northeast region."
                />
            </Helmet>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>About Us</h1>
            </section>
            <section className="text-left-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div class="text-left-content">
                                <div class="text-left-content-text">
                                    <div className='section-header'> 
                                    Who We Are
                                    </div>
                                    <h1>Aerial Application Pros</h1>
                                    <p>
                                    We are your trusted experts in aerial drone 
                                    applications across New York State and the Northeast region. With a 
                                    primary focus on combating invasive pests, our services 
                                    extend to aquatic and agricultural pests, as well as precise 3D mapping. 
                                    </p>
                                    <p>
                                    We understand the unique challenges 
                                    faced by various industries, and we tailor our solutions to meet your 
                                    specific needs. Whether you're a homeowner, farmer, or business owner, we 
                                    serve anyone in the Northeast, providing exceptional 
                                    results.
                                    </p>
                                    <p>
                                    Partner with us for professional drone-powered pest control by contacting us today!
                                    </p>
                                </div>   
                                <a class="main-button" href="/contact">Get A Quote<i className='fa fa-arrow-right'></i></a>              
                            </div>
                        </div>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Operating Drone Over Trees" id="text-left-img" src="assets/about/CNY_Drone_about.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-left-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-left-img' id="about-page-img">
                            <img decoding="async" alt="Rick with the Drone" id="text-left-img" src="assets/home/CNY_Drone_home_about.webp"/>
                        </div>
                        <div className='col-lg-6 about-right-img'>
                            <img decoding="async" alt="Drone in the Truck" id="text-left-img" src="assets/about/CNY_Drone_about_2.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a class="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default About;