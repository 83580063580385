import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Agricultural = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div id="agricultural-page" className='service-page'>
            <Helmet>
                <title>Agricultural Solutions</title>
                <meta name="description" 
                      content="With our large capacity drones, we tackle the devastation that fungus and thistle cause on local ecosystems. Furthermore, we utilize our advanced drone technology to provide cover crop seeding."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>
                <h1 className='page-header-top-title'>Our Services /</h1>
                <h1 className='page-header-title'>Agricultural Solutions</h1>
            </section>
            <section className="text-left-section service-left">
                <div class="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div class="text-left-content">
                                <div class="text-left-content-text">
                                    <h1>Fungicide Treatment</h1>
                                    <p>
                                    Fungus poses a significant threat to plants, causing diseases that can 
                                    devastate crops, gardens, and natural ecosystems. These fungal infections 
                                    can lead to wilting, yellowing leaves, and overall decline in plant health. 
                                    In severe cases, they can result in crop failures and economic losses to 
                                    your farming industry.
                                    </p>
                                    <p>
                                    We address this threat with our drone-based fungicide treatment. Using 
                                    advanced drone technology, we efficiently and precisely apply fungicides 
                                    to affected plants, ensuring maximum coverage and effectiveness. 
                                    Our drone-powered approach allows us to reach inaccessible areas, 
                                    such as tall crops or dense vegetation, effectively combating fungal 
                                    infections and promoting plant recovery.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Fungicide Treatment" id="text-left-img" src="assets/services/agricultural/CNY_Drone_ag.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service-page-gypsy-moth" className="text-right-section service-right">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Thistle Control" id="text-right-img" src="assets/services/agricultural/thistle.webp"/>
                        </div>
                        <div className='col-lg-6'>
                            <div class="text-right-content">
                                <div class="text-right-content-text">
                                    <h1>Thistle Control</h1>
                                    <p>
                                    Thistle poses a real threat to agricultural fields, pastures, and 
                                    natural habitats, with its invasive nature and ability to rapidly 
                                    spread. This resilient and prickly weed can outcompete desirable plants, 
                                    reducing crop yields and degrading grazing land. Its deep taproot 
                                    makes it difficult to eradicate through traditional means.
                                    </p>
                                    <p>
                                    At CNY Drone Services, we tackle the thistle threat using our 
                                    innovative drone technology. With our precision drone-based 
                                    herbicide application, we target thistle infestations with 
                                    maximum accuracy and minimal environmental impact. Our 
                                    drone-powered approach allows us to access hard-to-reach 
                                    areas and treat large expanses of land efficiently.
                                    </p>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-left-section service-left last-service-section">
                <div class="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div class="text-left-content">
                                <div class="text-left-content-text">
                                    <h1>Cover Crop Seeding</h1>
                                    <p>
                                    Cover crop seeding plays a vital role in sustainable agriculture, offering 
                                    numerous benefits to soil health, water quality, and overall crop productivity. 
                                    These strategically planted crops help prevent soil erosion, improve soil 
                                    structure, enhance nutrient cycling, and suppress weed growth.
                                    </p>
                                    <p>
                                    The CNY Drone Services team takes cover crop seeding to new heights with 
                                    our advanced drone technology. Our drones precisely distribute cover 
                                    crop seeds, ensuring even coverage and optimal germination. 
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Cover Crop Seeding" id="text-left-img" src="assets/services/agricultural/seeding.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a class="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Agricultural;