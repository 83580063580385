import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Aquatic = () => {

    useEffect(() => {  

        var contactVideo = document.getElementById("contact-video");

        contactVideo.oncanplaythrough = function() {
            contactVideo.muted = true;
            contactVideo.setAttribute("playsinline", "");
            contactVideo.play();
        }
        
        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }

    }, []);

    return (
        <div className='service-page'>
            <Helmet>
                <title>Aquatic Applications</title>
                <meta name="description" 
                      content="We harness the power of drones for effective removal of invasive aquatic plants such as the water chestnut and common reed as well as effective monitoring of harmful algal bloom near water bodies."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>
                <h1 className='page-header-top-title'>Our Services /</h1>
                <h1 className='page-header-title'>Aquatic Applications</h1>
            </section>
            <section className="text-left-section service-left">
                <div class="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div class="text-left-content">
                                <div class="text-left-content-text">
                                    <h1>Water Chestnut Treatment</h1>
                                    <p>
                                    Trapa natans, commonly known as Water Chestnut, is an invasive plant that poses a 
                                    significant threat to aquatic environments. This aggressive species forms dense 
                                    floating mats, impeding water flow, and hindering native species' growth. With its 
                                    sharp spines, it also poses risks to recreational activities, such as swimming 
                                    and boating. 
                                    </p>
                                    <p>
                                    At CNY Drone Services, we tackle the Water Chestnut threat head-on using innovative 
                                    drone-powered pest control techniques. Our targeted approach enables us to efficiently
                                    target water chestnuts and eradicate this invasive plant, restoring the health and 
                                    vitality of your water resources.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Water Chestnut Treatment" id="text-left-img" src="assets/services/aquatic/water_chestnut_new.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="service-page-gypsy-moth" className="text-right-section service-right">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <img decoding="async" alt="Common Reed" id="text-right-img" src="assets/services/aquatic/commonread.webp"/>
                        </div>
                        <div className='col-lg-6'>
                            <div class="text-right-content">
                                <div class="text-right-content-text">
                                    <h1>Combating the Common Reed <i>(Phragmite)</i></h1>
                                    <p>
                                    The invasive common reed, scientifically known as phragmites, poses a 
                                    significant threat to wetlands and coastal areas. This aggressive 
                                    plant species forms dense stands, outcompeting native vegetation, 
                                    and altering the natural habitat for various species. Its rapid 
                                    spread and extensive root system can disrupt water flow, leading 
                                    to drainage issues and increased fire risk.
                                    </p>
                                    <p>
                                    We offer effective solutions to combat the invasive common reed 
                                    using our state-of-the-art drone-powered pest control. With our 
                                    precision targeting and environmentally-friendly approach, we 
                                    can efficiently identify and treat Phragmites infestations, 
                                    restoring the ecological balance and preserving the integrity 
                                    of wetland ecosystems.
                                    </p>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="text-left-section service-left last-service-section">
                <div class="container">
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div class="text-left-content">
                                <div class="text-left-content-text">
                                    <h1>Harmful Algal Bloom Monitoring</h1>
                                    <p>
                                    Harmful Algal Blooms (HABs) pose a significant threat to water bodies, affecting 
                                    aquatic ecosystems and public health. These blooms occur when certain types of 
                                    algae multiply rapidly, releasing toxins that can harm fish, wildlife, and humans. 
                                    HABs can lead to oxygen depletion, water discoloration, and foul odors, 
                                    impacting the aesthetic appeal and usability of affected waters. 
                                    </p>
                                    <p>
                                    We employ drone technology to monitor and assess the presence of 
                                    harmful algal blooms. With our high-resolution aerial imagery 
                                    and advanced sensors, we can detect and track HABs in real-time, 
                                    providing valuable data for proactive management and mitigation 
                                    efforts.
                                    </p>
                                </div>                 
                            </div>
                        </div>
                        <div className='col-lg-6 drones-img'>
                            <img decoding="async" alt="Algae" id="text-left-img" src="assets/services/aquatic/algae.webp"/>
                        </div>
                    </div>
                </div>
            </section>
            <section id="home-contact-section">
                <div id="home-contact-image-body">
                    <video id="contact-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Don't Wait Contact" className="mobile_img" src="assets/home/CNY_Drone_home_contact_img.png"/>
                    <div id="home-contact-text">
                        <div className="home-why-us-content-text">
                            <h1>Don't Wait!</h1>
                            <p>
                            We have to order the insecticide in advance, limited supplies available. Contact us now to schedule our services.                           
                            </p>
                        </div> 
                        <a class="main-button" href="/contact">Get A Quote</a>
                    </div>
                </div>
            </section> 
        </div>
    );
}

export default Aquatic;