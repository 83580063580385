import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Home from './component/Home';
import Navbar from './component/Navbar';
import Footer from './component/Footer';
import Contact from './component/Contact';
import About from './component/About';
import News from './component/News';
import Contract from './component/Contract';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/font-awesome/css/font-awesome.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Insects from './component/Insects';
import Agricultural from './component/Agricultural';
import Aquatic from './component/Aquatic';
import Other from './component/Other';
import Media from './component/Media';
import Blog2 from './component/Blog2';
import CountryFolks from './component/CountryFolks';
import HowToProtectYourTreesFromThisYearsMothInfestation from './component/HowToProtectYourTreesFromThisYearsMothInfestation';
import Services from './component/Services';
import MothBlog from './component/MothBlog';
import UploadMap from './component/UploadMap';
import PageNotFound from './component/PageNotFound';
import ContactGypsy from './component/ContactGypsy';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Navbar/>
      <Routes>
        <Route path="" element={<App/>}>
          <Route index element={<Home/>}/>
          <Route path="*" element={<PageNotFound />} />
          <Route path="about" element={<About/>} />
          <Route path="contact" element={<Contact/>}/>
          <Route path="media" element={<Media/>}/>
          <Route path="news" element={<News/>}/>
          <Route path="contract" element={<Contract/>}/>
          <Route path="contact-option-gypsy" element={<ContactGypsy/>}/>
          <Route path="services" element={<Services/>}/>
          <Route path="what-to-do-spongy-moth" element={<MothBlog />}/>
          <Route path="DCNR-Urges-Forest-Landowners-And-Managers-Seeking-To-Treat-For-Spongy-Moth-Caterpillars-To-Act-Now" element={<Blog2 />}/>
          <Route path="how-to-protect-your-trees-from-this-years-moth-infestation" element={<HowToProtectYourTreesFromThisYearsMothInfestation/>}/>
          <Route path="invasive-insects" element={<Insects/>}/>
          <Route path="Advances-in-drones-for-pest-management" element={<CountryFolks/>}/>
          <Route path="agricultural-solutions" element={<Agricultural/>}/>
          <Route path="aquatic-applications" element={<Aquatic/>}/>
          <Route path="other-services" element={<Other/>}/>
        </Route>
      </Routes>
    <Footer/>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
