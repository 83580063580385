import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const HowToProtectYourTreesFromThisYearsMothInfestation = () => {

    useEffect(() => {  

        var headerVideo = document.getElementById("header-video");


        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='mothblog-page'>
            <Helmet>
                <title>How to protect your trees from this year’s moth infestation</title>
                <meta name="description" 
                      content="Living in the Finger Lakes region, you may know that spongy months — also called gypsy moths — show up en masse starting in the spring and remove the leaves from trees and other plants. “They literally swarm,” said Rick Jordan, operations manager of CNY Drone Services. “You’ll have huge masses all over the trees."
                />
                <link rel="canonical" href="https://www.tompkinsweekly.com/articles/how-to-protect-your-trees-from-this-years-moth-infestation/"></link>
                <meta property="og:site_name" content="Tompkins Weekly -"></meta>
                <meta property="og:type" content="article"></meta>
                <meta property="og:title" content="How to protect your trees from this year’s moth infestation - Tompkins Weekly"></meta>
                <meta property="og:description" content="Living in the Finger Lakes region, you may know that spongy months — also called gypsy moths — show up en masse starting in the spring and remove the leaves from trees and other plants. “They literally swarm,” said Rick Jordan, operations manager of CNY Drone Services. “You’ll have huge masses all over the trees. It’s estimated to be about <a href=&quot;https://www.tompkinsweekly.com/articles/how-to-protect-your-trees-from-this-years-moth-infestation/&quot;>[…]</a>"></meta>
                <meta property="og:url" content="https://www.tompkinsweekly.com/articles/how-to-protect-your-trees-from-this-years-moth-infestation/"></meta>
                <meta property="og:image" content="https://www.tompkinsweekly.com/wp-content/uploads/2023/03/CNY-Drone-photo.jpg"></meta>
                <meta property="og:image:secure_url" content="https://www.tompkinsweekly.com/wp-content/uploads/2023/03/CNY-Drone-photo.jpg"></meta>
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>News</h1>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News /
                        </div>
                        <h1><a href="/news">Back to All Posts</a></h1>  
                        <hr/>                     
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div>
                                    <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/>
                                    <div id="blockquote" className="card-body blockquote">
                                        <p id="mothblog-title" className="card-text">
                                            <strong>How to protect your trees from this year’s moth infestation</strong>
                                        </p>
                                    </div>
                                </div>     
                                <div>
                                    <img decoding="async" alt="How to Protect Your Trees from Gypsy Moths" className="card-img" src="/assets/news/howtoprotectyourtrees.jpg"/>
                                </div>   
                                <div id="blockquote" className="card-body blockquote">
                                    <p className='blog-text-smaller'>03/15/2023</p>
                                    <p className='blog-text'>
                                    Living in the Finger Lakes region, you may know that spongy months — also called gypsy moths — show up en masse starting in the spring and remove the leaves from trees and other plants.
                                    </p>
                                    <p className='blog-text'>
                                    “They literally swarm,” said Rick Jordan, operations manager of CNY Drone Services. “You’ll have huge masses all over the trees. It’s estimated to be about a million caterpillars per acre during an outbreak.”
                                    </p>
                                    <p className='blog-text'>
                                    All plant life is at risk from this infestation, but the creatures primarily feed on leafy trees, he said.
                                    </p>
                                    <p className='blog-text'>
                                    “They will eventually attack all the pine trees,” Jordan said. “They’ll drop down and start eating all the shrubs and things like that, and when they start getting hungry enough, they can actually drop down and start eating your grass, too. So it gets pretty bad. During a very bad outbreak, there’s so many of them that you can literally hear them chewing on the leaves.”
                                    </p>
                                    <p className='blog-text'>
                                    Additionally, their waste dropping sounds like it’s raining, he said. </p>
                                    <p className='blog-text'>
                                    “They poop so much that it will actually leave a coating on people’s yards, their driveways, all over their houses, their patios, their pools, and it’s just literally covered in a film of poop,” Jordan said. “And it’s horrible.”
                                    </p>
                                    <p className='blog-text'>
                                    Fortunately, you can protect your trees and property with a specialized insecticide, delivered by drone from CNY Drone Services. Drone delivery ensures a targeted and thorough treatment without harming your land or family.
                                    </p>
                                    <p className='blog-text'>
                                    “It’s safe for organic use, like on organically grown fruits or vegetables,” Jordan said. “You can use this and still maintain your organic growth, basically. It’s extremely safe for pets, wildlife, even humans. It will only target or only have an effect on the gypsy moths, on that family of caterpillars.”
                                    </p>
                                    <p className='blog-text'>
                                    Insecticides are the only way to manage a spongy moth infestation, as other deterrents are temporary — meaning when the moths are done with the neighbor’s trees, they’ll come back to yours, Jordan said.
                                    </p>
                                    <p className='blog-text'>
                                    “It’s virtually impossible to actually kill all of the caterpillars in an area in order to stop the outbreak, so it’s more a case of managing the impact of them,” he said.
                                    </p>
                                    <p className='blog-text'>
                                    Drone delivery offers better coverage than other methods because it gives an even covering, especially on residential plots that would benefit from aerial application but are too small to use delivery by airplane or helicopter, he said. Drone delivery is also beneficial because it can reach the underside of trees to get to the caterpillars that are feeding on lower leaves.
                                    </p>
                                    <p className='blog-text'>
                                    “We’re also saturating the entire habitat of the caterpillars, so there’s essentially no safe place for them to be chewing on leaves,” Jordan said.
                                    </p>
                                    <p className='blog-text'>
                                    Because the chemical in the insecticide has limited availability, it’s a good idea not to delay scheduling an appointment.
                                    </p>
                                    <p className='blog-text'>
                                    “Our insecticide is only produced once a year, and it has a shelf life, so it’s not like we can just stockpile this stuff and then use it next year,” Jordan said.</p>
                                    <p className='blog-text'>
                                    To help with both the cost and effectiveness of treatment, you can work with neighbors to schedule treatment together so as to treat a larger area and minimize cost per household.</p>
                                    <p className='blog-text'>
                                    “It essentially comes down to, save your trees,” Jordan said. “The cost of our treatment versus the cost of hiring a company to come do a tree removal because your tree died, it’s a drop in the well. Most of these trees are irreplaceable, why leave it up to chance?
                                    </p>
                                     <br/>
                                    <p className='blog-text'>
                                     Read the full article <span><a href="https://www.tompkinsweekly.com/articles/how-to-protect-your-trees-from-this-years-moth-infestation/">here</a></span>.
                                    </p>
                                    <br/><br/>
                                    <hr/>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HowToProtectYourTreesFromThisYearsMothInfestation;