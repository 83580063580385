import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const PageNotFound = () => {

    useEffect(() => {        
        
        const nav = document.getElementById("navid");
        const navLinkDark = document.querySelectorAll('[id="navlink"]');

        const servicesDropdown = document.getElementById("services-dropdown");

        nav.style.backgroundColor = '#fff';
        servicesDropdown.style.color = '#000';

        const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                id.style.color = 'black';
         });
         


        if (window.innerWidth < 700) {
            const navLinkDarkArray = [...navLinkDark];
            navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            }   );
        }

        window.addEventListener("scroll", () => { 
            
            servicesDropdown.style.color = '#000';
            const navLinkDarkArray = [...navLinkDark];
                navLinkDarkArray.forEach(id => {
                    id.style.color = 'black';
            });

            if (!window.pageYOffset && window.innerWidth > 700) { 
                servicesDropdown.style.color = '#000';

                const navLinkDarkArray = [...navLinkDark];
                    navLinkDarkArray.forEach(id => {
                        id.style.color = 'black';
                });

          }
        });

    }, []);

    return ( 
        <div>
            <Helmet>
                <title>404: Page Not Found</title>
                <meta name="description" 
                      content="Una Web Solutions is a web design 
                            company started to empower small businesses and 
                            nonprofits."
                />
            </Helmet>
            <section className='pagenotfound-section'>
                <div className='pagenotfound-img'>
                    <img src='assets/404.png'/>
                </div>
                <h1>Oops!</h1>
                <h2>Sorry. We couldn't find the page you're looking for.</h2>
                <h2>Please check the URL for any typos.</h2>
                <div class="text-left-content">
                    <div class="text-left-content-text">
                        <a class="main-button" href="/">Go Back Home<i class="fa fa-arrow-right" aria-hidden="true"></i></a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default PageNotFound;