import React from 'react';
import { useEffect } from 'react';
import {Helmet} from "react-helmet";

const Blog2 = () => {

    useEffect(() => {  

        var headerVideo = document.getElementById("header-video");


        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }
        
    }, []);

    return (
        <div className='mothblog-page'>
            <Helmet>
                <title>DCNR Urges Forest Landowners And Managers Seeking To Treat For Spongy Moth Caterpillars To Act Now</title>
                <meta name="description" 
                      content="The invasive spongy moth and its caterpillars can decimate a garden. Here's how to fight back."
                />
            </Helmet>
             <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                </div>                
                <h1 className='page-header-title'>News</h1>
            </section>
            <section id="home-news-section">
                <div className='container'>
                    <div className='home-news-header-text'>
                        <div className='section-header'> 
                            News /
                        </div>
                        <h1><a href="/news">Back to All Posts</a></h1>  
                        <hr/>                     
                    </div>
                    <div className="testimonials text-center">
                        <div id="testimonial-card" className="card border-light text-center">
                            <div className="row container">
                                <div>
                                    <img decoding="async" alt="CNY Drone Services Logo" id="home_blog_logo" className="card-img" src="assets/CNY_Drone_Services_Logo.webp"/>
                                    <div id="blockquote" className="card-body blockquote">
                                        <p id="mothblog-title" className="card-text">
                                            <strong>DCNR Urges Forest Landowners And Managers Seeking To Treat For Spongy Moth Caterpillars To Act Now</strong>
                                        </p>
                                    </div>
                                </div>     
                                <div>
                                    <img decoding="async" alt="Multiple Hungry Caterpillars" className="card-img" src="assets/news/news2.jpg"/>
                                </div>   
                                <div id="blockquote" className="card-body blockquote">
                                    <p className='blog-text-smaller'>01/25/2024</p>
                                    <p className='blog-text'>
                                    The Department of Conservation and Natural Resources is urging forest managers and woodlot owners who are seeking to treat for spongy moth (lymantria dispar dispar) caterpillars this spring to begin planning treatment operations now.
                                    </p>
                                    <p className='blog-text'>
                                    With approximately 70 percent of all forest lands in the Commonwealth privately owned, their participation is essential in helping to protect trees and forests from spongy moth caterpillar feeding.
                                    </p>
                                    <p className='blog-text'>
                                    Spongy moth populations have been observed at outbreak levels the past three years and foresters are encouraging those who own or manage private forests to book aerial spraying while there is still ....
                                    </p>
                                    <br/>
                                    <p className='blog-text'>
                                     Read the full article <span><a href="https://www.media.pa.gov/pages/DCNR_details.aspx?newsid=1052">here</a></span>.
                                    </p>
                                    <br/><br/>
                                    <hr/>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Blog2;