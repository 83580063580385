import React from 'react';
import { useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
import JotformEmbed from 'react-jotform-embed';
import { useLocation } from 'react-router-dom';

const ContactGypsy = () => {

    useEffect(() => {   

        var headerVideo = document.getElementById("header-video");

        headerVideo.oncanplaythrough = function() {
            headerVideo.muted = true;
            headerVideo.setAttribute("playsinline", "");
            headerVideo.play();
        }

            // Get all image elements
            const imageElements = document.querySelectorAll('.portfolio-img-body');
          
            // Get all content elements
            const contentElements = document.querySelectorAll('.portfolio-img-content');
          
            // Get the first image element
            const firstImageElement = imageElements[1];
          
            // Get the data-image attribute of the first image element
            const firstImageNumber = firstImageElement.getAttribute('data-image');
          
            // Get the content associated with the first image
            const firstActiveContent = document.querySelector(`[data-content="${firstImageNumber}"]`);
          
            // Add 'active-content' class to the first content element
            firstActiveContent.classList.add('active-content');
          
            // Add a border bottom of 2px solid red to the first image element
            firstImageElement.style.borderBottom = "2px solid #60A7C0";
          
            // Attach click event listeners to the image elements
            imageElements.forEach((imageElement) => {
              imageElement.addEventListener('click', () => {
                // Get the data-image attribute to determine which content to show
                const imageNumber = imageElement.getAttribute('data-image');
          
                // Hide all content elements
                contentElements.forEach((contentElement) => {
                  contentElement.classList.remove('active-content');
                });
          
                // Show the content associated with the clicked image
                const activeContent = document.querySelector(`[data-content="${imageNumber}"]`);
                activeContent.classList.add('active-content');
          
                // Remove the border from all image elements
                imageElements.forEach((element) => {
                  element.style.borderBottom = "none";
                });
          
                // Add a border bottom of 2px solid red to the clicked image element
                imageElement.style.borderBottom = "2px solid #60A7C0";
              });
            });

    }, []);

    return (
        <div id="contact-page">
            <Helmet>
                <title>Contact Us</title>
                <meta name="description" 
                      content="We are more than happy to answer any of your questions and will get back to you as soon as possible."
                />
            </Helmet>
            <section className='page-header'>
                <div className="page-header-img-body my-5">
                    {/* <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img> */}
                    <video id="header-video" playsinline autoplay muted loop>
                        <source src="assets/home/droneflying_bg.mp4" type="video/mp4"/>
                    </video>
                    <img decoding="async" alt="Flying Drone Page Header" className='page-header-img' src='assets/home/CNY_Drone_service_area.webp'></img>
                    </div>                
                <h1 className='page-header-title'>Contact</h1>
            </section>
            <section className="text-right-section">
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6 about-img'>
                            <div id="home-hero-image-body">
                                <img decoding="async" alt="Contact City Skyline" src="assets/contact/drone_new_contact_.png"/>
                                <div id="home-hero-content">
                                    <div class="home-hero-content">
                                        <div class="home-hero-content-text">
                                            <div className='section-header'> 
                                                Contact
                                            </div>
                                            <h1>Get A Quote</h1>
                                            <p>We are more than happy to answer any of your questions and will get back to you as soon as possible.</p>
                                            <div className='contact-links'>
                                                <h2>Email Us</h2>
                                                <p>info@cnydroneservices.com</p>
                                                <h2>Call Us</h2>
                                                <p>315-351-6516</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                                <h3>Choose Service Type Below</h3>
                                <div className='row contact-btns'>
                                    <div className='col-lg-4'>
                                        <div className='portfolio-img-body' data-image="1">
                                            <button className='portfolio-img'>General</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='portfolio-img-body' data-image="2">
                                            <button className='portfolio-img'>Gypsy Moth</button>
                                        </div>
                                    </div>
                                    <div className='col-lg-4'>
                                        <div className='portfolio-img-body' data-image="3">
                                            <button className='portfolio-img'>Agricultural</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='portfolio-popup portfolio-img-content' data-content="1">
                                    <div className='container'>
                                        <iframe id="general-popup" src="https://form.jotform.com/233137528735157" />
                                    </div>
                                </div>
                                <div className='portfolio-popup portfolio-img-content  active-content' data-content="2">
                                    <div className='container'>
                                        <iframe id="gypsy-popup" className='hiddencontact' src="https://form.jotform.com/233137573659162" />
                                    </div>
                                </div>
                                <div className='portfolio-popup portfolio-img-content' data-content="3">
                                    <div className='container'>
                                        <iframe id="agri-popup" src="https://form.jotform.com/233137077289160" />
                                    </div>
                                </div>
                        {/* <div className='jotform'> */}
                            {/* <iframe src="https://form.jotform.com/233135560598158" /> */}
                            {/* <JotformEmbed src="https://form.jotform.com/233135560598158" /> */}
                        {/* </div> */}
                            {/* <div class="text-right-content">
                                <form className="contact-form w-100" 
                                    name="Client" 
                                    method="POST" 
                                    data-netlify="true" 
                                    netlify-honeypot="bot-field"
                                    id="contact"
                                    >
                                    <p class="hidden">
                                        <label>
                                        Don't fill this out if you're human: <input name="bot-field" />
                                        </label>
                                    </p>
                                    <div>  
                                        <input type="hidden" name="form-name" value="Client" />
                                        <input type="hidden" name="subject" 
                                        value="Client from Website Contact Form" />
                                        <div className="form-group">
                                            <div className='row'>
                                                <div className='col-lg-6'>
                                                    <label className='form-label'>First Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="first_name"
                                                        name="First_name"
                                                    />
                                                </div>
                                                <div className='col-lg-6'>
                                                    <label className='form-label'>Last Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="last_name"
                                                        name="Last_name"
                                                    />
                                                </div>
                                            </div>
                                            <label className='form-label'>Email</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="Email"
                                            />
                                            <p>
                                                <label>
                                                    <input 
                                                        id="emailbox"
                                                        type="checkbox" 
                                                        name="Email Status" 
                                                        value="No Email"/> Click here if you have no email address
                                                </label>
                                            </p>
                                            <label className='form-label'>Street</label>
                                            <input 
                                                type="text" 
                                                className="form-control"
                                                id="street" 
                                                name="Street"
                                            />
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    <label className='form-label'>City</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="city"
                                                        name="City"
                                                    />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className='form-label'>State</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="state"
                                                        name="State"
                                                    />
                                                </div>
                                                <div className='col-lg-4'>
                                                    <label className='form-label'>Zip</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="zip"
                                                        name="Zip"
                                                    />
                                                </div>
                                            </div>
                                            <label className='form-label'>Phone Number</label>
                                            <input 
                                                type="tel" 
                                                className="form-control"
                                                id="phone" 
                                                name="Phone"
                                            />
                                        </div>                                                                               
                                    </div>
                                    <div>
                                        <label className='form-label'>How Can We Help?</label>
                                        <textarea
                                            className="form-control"
                                            id="FormControlTextarea"
                                            placeholder="Your Message..."
                                            rows="10"
                                            name="Message"
                                        ></textarea>
                                    </div>
                                    <div className='row'>
                                        <div className='col'></div>
                                        <div className='col'>
                                            <br/>
                                            <button type="submit" id="contact-us-submit-btn" className="contact-btn">Submit Message</button> 
                                        </div>
                                    </div>
                                </form>
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default ContactGypsy;