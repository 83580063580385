import './styles/App.css';
import './styles/about.css';
import './styles/contact.css';
import './styles/footer.css';
import './styles/home.css';
import './styles/navbar.css';
import './styles/services.css';
import './styles/news.css';
import './styles/media.css';
import './styles/contract.css';
import './styles/pagenotfound.css';

import Home from './component/Home';
import Contact from './component/Contact';
import ContactGypsy from './component/ContactGypsy';
import UploadMap from './component/UploadMap';
import About from './component/About';
import News from './component/News';
import Contract from './component/Contract';
import Insects from './component/Insects';
import Agricultural from './component/Agricultural';
import Aquatic from './component/Aquatic';
import Other from './component/Other';
import Media from './component/Media';
import Blog2 from './component/Blog2';
import CountryFolks from './component/CountryFolks';
import HowToProtectYourTreesFromThisYearsMothInfestation from './component/HowToProtectYourTreesFromThisYearsMothInfestation';
import Services from './component/Services';
import MothBlog from './component/MothBlog';
import PageNotFound from './component/PageNotFound';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
      <Routes>
        <Route path="*" element={<PageNotFound />} />
        <Route path="" element={<Home />} />
        <Route path="about" element={<About/>} />
        <Route path="about-us" element={<About/>} />
        <Route path="contact" element={<Contact/>} />
        <Route path="media" element={<Media/>}/>
        <Route path="news" element={<News/>}/>
        <Route path="contract" element={<Contract/>}/>
        <Route path="contact-option-gypsy" element={<ContactGypsy/>}/>
        <Route path="services" element={<Services/>}/>
        <Route path="what-to-do-spongy-moth" element={<MothBlog />}/>
        <Route path="DCNR-Urges-Forest-Landowners-And-Managers-Seeking-To-Treat-For-Spongy-Moth-Caterpillars-To-Act-Now" element={<Blog2 />}/>
        <Route path="how-to-protect-your-trees-from-this-years-moth-infestation" element={<HowToProtectYourTreesFromThisYearsMothInfestation/>}/>
        <Route path="invasive-insects" element={<Insects/>}/>
        <Route path="Advances-in-drones-for-pest-management" element={<CountryFolks/>}/>
        <Route path="agricultural-solutions" element={<Agricultural/>}/>
        <Route path="aquatic-applications" element={<Aquatic/>}/>
        <Route path="other-services" element={<Other/>}/>
      </Routes>
  );
}

export default App; 
